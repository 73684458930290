import { USER_ROLE_KEYS } from '../../../../utils/constants/menu.constants';
import { userTypes } from '../../../../utils/constants/user-types.constants';
import { getUserRoles } from '../../../../utils/utils';
import { ALL_CARS_OCB_LIVE_FILTER } from '../constants/filters.constants';


const teamLeadFilters = [ALL_CARS_OCB_LIVE_FILTER[0].id, ALL_CARS_OCB_LIVE_FILTER[1].id, ALL_CARS_OCB_LIVE_FILTER[3].id];

const defaultFilter = ALL_CARS_OCB_LIVE_FILTER[0];


const roleConditions = [
  {
    roleKey: USER_ROLE_KEYS.C2B_VIZ_PANEL_NO_LIVE_FEED,
    filterOptions: ALL_CARS_OCB_LIVE_FILTER[2]
  },
  {
    roleKey: USER_ROLE_KEYS.C2B_VIZ_PANEL_PDTP,
    filterOptions: ALL_CARS_OCB_LIVE_FILTER[5]
  }
];


export const getOcbLiveFilter = () => {
  const userRoles = getUserRoles();
  for (const condition of roleConditions) {
    if (userRoles.includes(condition.roleKey)) {
      return condition.filterOptions;
    }
  }
  return defaultFilter;
};

export const roleAccessMap = {
  [userTypes.PROCUREMENT_ASSOCIATE]: [ALL_CARS_OCB_LIVE_FILTER[0].id, ALL_CARS_OCB_LIVE_FILTER[1].id, ALL_CARS_OCB_LIVE_FILTER[2].id, ALL_CARS_OCB_LIVE_FILTER[3].id, ALL_CARS_OCB_LIVE_FILTER[4].id],
  [USER_ROLE_KEYS.C2B_VIZ_PANEL_NO_LIVE_FEED]: [ALL_CARS_OCB_LIVE_FILTER[2].id, ALL_CARS_OCB_LIVE_FILTER[3].id, ALL_CARS_OCB_LIVE_FILTER[4].id],
  [userTypes.TEAM_LEAD]: teamLeadFilters,
  [userTypes.REGION_HEAD]: teamLeadFilters,
  [userTypes.RELATIONSHIP_MANAGER]: teamLeadFilters,
  [userTypes.DEALERSHIP_MANAGER]: teamLeadFilters,
  [userTypes.TARGET_PRICE_PROCUREMENT_ASSOCIATE]: teamLeadFilters,
  [userTypes.PRICE_DISCOVERY]: [ALL_CARS_OCB_LIVE_FILTER[5].id, ALL_CARS_OCB_LIVE_FILTER[3].id],
};

export const hasRADetailsAccess = (userRoles) => {
  const requiredRoles = [userTypes.DEALERSHIP_MANAGER, userTypes.RELATIONSHIP_MANAGER, userTypes.PRICE_DISCOVERY];

  return !requiredRoles.some(role => userRoles?.includes(role));
};

