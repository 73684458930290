import "../common/assets/styles/common.scss";
import "./car-details.scss";
import AppTable from "../common/components/app-table"
import { FIELDS_KEY, TABLE_LABELS } from "../common/constants/table.constants"
import { useEffect, useState } from "react";
import { currencyFormat } from "../common/utils/utils";

const TpView = ({ carDetails }) => {
  const tableValues = {
    suggestedPrice: '',
  }
  const [data, setData] = useState([tableValues]);

  useEffect(() => {
    let tpDetails = carDetails?.tpDetails;
    if (tpDetails) {
      tableValues.dstp = currencyFormat(tpDetails?.dstp)
      setData([{ ...tableValues }]);
    }
  }, [carDetails])

  const SafeCells = safeCellProps => {
    const event = {
      dataItem: safeCellProps.data
    };

    return (
      <td onClick={() => navigateTo(event)}>
        {safeCellProps.children}
      </td>
    );
  };
  const tableColumns = [
    {
      field: FIELDS_KEY.SuggestedPrice,
      title: TABLE_LABELS.SuggestedPrice,
      cells: ({ dataItem }) => (
        <SafeCells data={dataItem}>
          <span>{dataItem.dstp}</span>
        </SafeCells>
      ),
    },
  ];

  return (
    <div className="current-bids-grid app-table-view-sm app-table">
      <AppTable
        data={data}
        headerData={[
          ...tableColumns
        ]}
        isHoverable={false}
        totalCount={data.length}
        handlePageChange={() => { }}
        pageable={false}
      />
    </div>
  );
};
export default TpView;
