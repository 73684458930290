import React, { useEffect, useRef, useState } from 'react'

import { EVENT_CAR_SOURCE, FIELDS_KEY } from '../../common/constants/table.constants'
import CallIcon from '../../common/assets/images/callIcon'
import './call-table-cell.scss'
import PlayIcon from '../../common/assets/images/playIcon'
import PauseIcon from '../../common/assets/images/pauseIcon'
import AppChips from '../../common/components/app-chips'
import { Button } from '@progress/kendo-react-buttons'
import { callDealer, setCorrelationId, setOngoingCallStatus } from '../../store/actions/myDealers'
import { useDispatch, useSelector } from 'react-redux'
import { CALL_EVENTS } from '../../common/constants/events.constants'
import { convertMilliseconds, getCallEvent } from '../../common/utils/utils'
import { getCallRecording } from '../../store/actions/callLogs'


const CallTableCell = ({ dataItem, field }) => {

  const Date = () => {
    return (
      <span>
        {dataItem.date || 'N/A'}
      </span>
    )
  }

  const Time = () => {
    return (
      <span>
        {dataItem.time || 'N/A'}
      </span>
    )
  }

  const Action = () => {

    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.allCars);

    const handleCallDealer = () => {

      const { dealerId, destinationNumber } = dataItem;
      dispatch(callDealer({ dealerId, userId: userInfo?.id, callingNumber: destinationNumber })).then((resp) => {
        dispatch(setCorrelationId(resp?.clientCorrelationId));
        getCallEvent(resp, EVENT_CAR_SOURCE.callLogs)
        dispatch(setOngoingCallStatus(true));
      }
      );

    }

    return (
      <div className='call-log-action'>
        <Button onClick={() => handleCallDealer()}>
          <CallIcon fill="#EF6E0B" />
          <span className='call-text'>Call</span>
        </Button>
      </div>
    )
  }

  const DealerNumber = () => {
    return (
      <span>
        {dataItem.destinationNumber || 'N/A'}
      </span>
    )
  }

  const DealerCode = () => {
    return (
      <span>
        {dataItem.dealerCode || 'N/A'}
      </span>
    )
  }

  const DealerName = () => {
    return (
      <span className='table-text'>
        {dataItem.dealerName || 'N/A'}
      </span >
    )
  }

  const CallType = () => {
    return (
      <span>
        {dataItem.callType || 'N/A'}
      </span>
    )
  }

  const OverallCallStatus = () => {

    const callStatus = dataItem?.overallCallStatus === CALL_EVENTS.ANSWERED ? 'Connected' : dataItem.overallCallStatus;
    return (
      <AppChips className={` call-status-container ${dataItem?.overallCallStatus === CALL_EVENTS.ANSWERED ? "primary-chips" : "missed-call-chip"}`} data={[callStatus]} />
    )
  }

  const HangupCause = () => {
    return (
      <span className='table-text'>
        {dataItem.destinationHangupCause || 'N/A'}
      </span>
    )
  }

  const CallerStatus = () => {
    return (
      <span>
        {dataItem.callerStatus || 'N/A'}
      </span>
    )
  }

  const OverallConversationTime = () => {
    return (
      <span>
        {convertMilliseconds(dataItem.conversationDuration) || 'N/A'}
      </span>
    )
  }

  const CallerName = () => {
    return (
      <span className='table-text'>
        {dataItem.callerName || 'N/A'}
      </span>
    )
  }

  const Recording = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const audioUrl = dataItem?.recordingUrl;
    const dispatch = useDispatch();
    const [callRecording, setCallRecording] = useState(null);

    const togglePlayPause = () => {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        if (!callRecording) {
          dispatch(getCallRecording({ callId: dataItem.clientCorrelationId })).then((res) => {
            setCallRecording(res);
          })
        }
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };

    if (!audioUrl || dataItem?.overallCallStatus === CALL_EVENTS.MISSED || dataItem?.overallCallStatus === CALL_EVENTS.INITIATED) {
      return <span className='table-text'>N/A</span>;
    }


    return (
      <div className="recording-container">
        <div className="play-button" onClick={togglePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </div>
        <audio ref={audioRef} src={callRecording?.presignedUrl} />
      </div>
    );
  };


  return (
    <React.Fragment>
      <td className="k-table-td relative" onClick={() => { }}>
        {
          {
            [FIELDS_KEY.Date]: <Date />,
            [FIELDS_KEY.Time]: <Time />,
            [FIELDS_KEY.ACTION]: <Action />,
            [FIELDS_KEY.DealersCode]: <DealerCode />,
            [FIELDS_KEY.Number]: <DealerNumber />,
            [FIELDS_KEY.DealerName]: <DealerName />,
            [FIELDS_KEY.CallType]: <CallType />,
            [FIELDS_KEY.OverallCallStatus]: <OverallCallStatus />,
            [FIELDS_KEY.HangupCause]: <HangupCause />,
            [FIELDS_KEY.CallerStatus]: <CallerStatus />,
            [FIELDS_KEY.OverallConversationTime]: <OverallConversationTime />,
            [FIELDS_KEY.CallerName]: <CallerName />,
            [FIELDS_KEY.Recording]: <Recording />,

          }[field]
        }
      </td>
    </React.Fragment>
  )
}

export default CallTableCell