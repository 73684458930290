import React from 'react';
import { Button } from '@progress/kendo-react-buttons';

import CallModal from './call-modal';
import './contact-cell.scss';
import CallIcon from '../../assets/images/callIcon';
import ContactIcon from '../../assets/images/contactIcon';

const ContactCell = ({ selectedContact, setSelectedContact, dealerId, contacts, handleCallDealer, tableRef, dealerName }) => {

  const handleContactIconClick = () => {
    if (selectedContact === dealerId) {
      setSelectedContact(null);
    } else {
      setSelectedContact(dealerId);
    }
  };

  return (
    <>
      <div className='contact-container'>
        <Button
          className={`call-button ${contacts?.length == 0 ? 'no-contact-button' : ''}`}
          onClick={() => handleCallDealer(dealerId, contacts)}
          disabled={contacts?.length == 0}
        >
          <CallIcon />
          <span className='call-text'>Call</span>
        </Button>
        <Button
          className={`contact-icon-button ${contacts?.length == 0 ? 'no-contact-button' : ''}`}
          onClick={handleContactIconClick}
          disabled={contacts?.length == 0}
        >
          <ContactIcon fill={contacts?.length == 0 ? '#fff' : null} />
        </Button>
      </div>
      {selectedContact === dealerId && (
        <CallModal
          dealerId={dealerId}
          dealerContactNumbers={contacts}
          dealerName={dealerName}
          closeModal={() => setSelectedContact(null)}
          ref={tableRef}
        />
      )}
    </>
  );
}

export default ContactCell;
