import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Loader } from "@progress/kendo-react-indicators";

import "./tab-current-bids.scss";
import AppTable from "../app-table";
import { vizPanelService } from "../../../services";
import { currencyFormat, getDateTime, getUserId, getReqParams, getCallEvent } from "../../utils/utils";
import { EVENT_CAR_SOURCE, FIELDS_KEY, INITIAL_TABLE_SETTINGS, TABLE_LABELS } from "../../constants/table.constants";
import { callDealer, setCorrelationId, setOngoingCallStatus } from "../../../store/actions/myDealers";
import ContactCell from "../contact-cell";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

const TabCurrentBids = ({ appointmentId }) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [page, setPage] = useState(INITIAL_TABLE_SETTINGS);
	const tableRef = useRef(null);
	const [selected, setSelected] = useState(0);

	const [selectedContact, setSelectedContact] = useState(null);
	const { userInfo } = useSelector((state) => state.allCars);

	const dispatch = useDispatch();

	useEffect(() => {
		refresh();
	}, [appointmentId, page, selected]);


	const refresh = () => {
		if (appointmentId && userInfo?.id) {
			getCurrentBids();
		}
	};

	const getCurrentBids = () => {
		let reqParams = getReqParams({ userId: getUserId(userInfo), page: page.skip, size: page.take });
		setIsLoading(true);

		vizPanelService.geCurrentBids(appointmentId, reqParams, selected)
			.then((res) => {
				let bids = res?.data?.length > 0 ? res : [];
				setData(bids);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const SafeCells = (safeCellProps) => {
		const event = {
			dataItem: safeCellProps.data
		};
		return (
			<td className={safeCellProps?.className}>
				{safeCellProps.children}
			</td>
		);
	};

	const maskString = (numbers) => {
		if (Array.isArray(numbers)) {
			if (numbers.length === 0) return [];
			return numbers.map((number) => {
				return number.replace(/./g, '*');
			});
		}
		if (!numbers) return '';
		return '*'.repeat(numbers.length);
	};

	const handleCallDealer = (dealerId, contacts) => {
		const callingNumber = contacts.find(contact => contact.isDefault).number || contacts[0].number;
		dispatch(callDealer({ dealerId, userId: userInfo?.id, callingNumber })).then((resp) => {
			getCallEvent(resp, EVENT_CAR_SOURCE.cars)
			dispatch(setCorrelationId(resp?.clientCorrelationId));
			dispatch(setOngoingCallStatus(true));
		}
		);

	}


	const tableColumns = [
		{
			field: FIELDS_KEY.DealerCode,
			title: TABLE_LABELS.DealerCode,
			cells: ({ dataItem }) => {

				const displayDealerCode = !dataItem?.dealerDetails?.hideDealerDetails ? dataItem?.dealerDetails?.dealerCode : maskString(dataItem?.dealerDetails?.dealerCode);
				return <SafeCells data={dataItem}>
					<div className="grid-value">{displayDealerCode}</div>
				</SafeCells>
			},
		},
		{
			field: FIELDS_KEY.DealerName,
			title: TABLE_LABELS.DealerName,
			cells: ({ dataItem }) => {
				const displayDealerName = !dataItem?.dealerDetails?.hideDealerDetails ? dataItem?.dealerDetails?.dealerName : maskString(dataItem?.dealerDetails?.dealerName);
				return (
					<SafeCells data={dataItem} className="highlight-label">
						<div className="grid-label">{displayDealerName}</div>
					</SafeCells>
				);
			},
		},
		{
			field: FIELDS_KEY.Amount,
			title: TABLE_LABELS.Amount,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div className="grid-value">{currencyFormat(dataItem?.bidDetails?.bidAmount)}</div>
				</SafeCells>
			),
			maxWidth: 150
		},
		{
			title: FIELDS_KEY.BidTime,
			field: TABLE_LABELS.BidTime,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div className="grid-value">
						{getDateTime(dataItem?.bidDetails?.bidTime)}
					</div>
				</SafeCells>
			),
			minWidth: 150
		},
		{
			field: FIELDS_KEY.ContactNumber,
			title: TABLE_LABELS.ContactNumber,
			cells: ({ dataItem }) => {

				const displayContact = !dataItem?.dealerDetails?.hideDealerDetails ? dataItem?.dealerDetails?.contactNumbers : maskString(dataItem?.dealerDetails?.contactNumbers);
				return (
					<SafeCells data={dataItem}>
						<div className="grid-value">
							<ContactCell dealerName={dataItem?.dealerDetails?.dealerName} tableRef={tableRef} dealerId={dataItem?.dealerDetails?.dealerId} contacts={displayContact} setSelectedContact={setSelectedContact} selectedContact={selectedContact} handleCallDealer={handleCallDealer} />
						</div>
					</SafeCells>
				);
			},
			minWidth: 180
		}
	];

	const handlePageChange = pageNumber => {

		setPage({
			skip: pageNumber.page.skip / pageNumber.page.take,
			take: pageNumber.page.take
		});

	}

	const handleSelect = (e) => {
		setSelected(e.selected);
	};

	return (
		<div className="tab-current-bids" ref={tableRef}>
			{isLoading ? (
				<div className="loader-container">
					<Loader type="infinite-spinner" size="medium" />
				</div>
			) :
				<div className="current-bids-grid app-table table-as-grid" ref={tableRef}>
					<TabStrip className="current-bids-tabs" selected={selected} onSelect={handleSelect}>

						<TabStripTab title="Current Bids">
							<AppTable
								data={data?.data || []}
								headerData={[...tableColumns]}
								isHoverable={false}
								totalCount={data.length}
								handlePageChange={() => { }}
								pageable={false}
								take={data?.length}
							/>

						</TabStripTab>

						<TabStripTab title="All Bids">
							<AppTable
								data={data?.data || []}
								headerData={[...tableColumns]}
								isHoverable
								totalCount={data?.pagination?.totalElements}
								skip={page.skip}
								take={page.take}
								handlePageChange={handlePageChange}

							/>

						</TabStripTab>

					</TabStrip>

				</div>
			}
		</div>
	);
};

export default TabCurrentBids;
